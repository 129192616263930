/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Component } from "react"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { ReactComponent as Logo } from "../assets/svg/logo.svg"
import AngleCover from "./angleCover"
import { Location } from "@reach/router"
import { HeaderBackgroundContainer1 } from "./headerBackground1"
import { HeaderBackgroundContainer2 } from "./headerBackground2"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      homeimage: "home",
      headerType: props.headerType,
    }
  }

  componentDidMount = () => {
    this.rotateBackground()
  }

  rotateBackground = () => {
    let homeImages = [
        {
          name: "home",
        },
        {
          name: "comics",
        },
      ],
      randomIndex = randomNum(0, 1)

    function randomNum(minVal, maxVal) {
      let r
      do {
        r = Math.random()
      } while (r === 1)
      return minVal + Math.floor(r * (maxVal + 1 - minVal))
    }
    this.setState({
      homeimage: homeImages[randomIndex].name,
      headerType:
        this.state.headerType !== "home"
          ? this.state.headerType
          : homeImages[randomIndex].name,
    })
  }

  getHeaderColors = () => {
    let colors = ["#930009", "#980069"],
      headerType = this.state.headerType
    switch (headerType) {
      case "home":
        colors[0] = "#ea410e"
        colors[1] = "#980069"
        break
      case "portfolio":
        colors[0] = "#001b4d"
        colors[1] = "#4d0099"
        break
      case "paypal":
        colors[0] = "#022069"
        colors[1] = "#019cde"
        break
      case "comics":
        colors[0] = "#ea410e"
        colors[1] = "#980069"
        break
      case "dnb":
        colors[0] = "#008fb3"
        colors[1] = "#005266"
        break
      case "phunware":
        colors[0] = "#000066"
        colors[1] = "#003d99"
        break
      case "contact":
        colors[0] = "#0066ff"
        colors[1] = "#009961"
        break
      case "simpsons":
        colors[0] = "#ead656"
        colors[1] = "#fcb207"
        break
      case "resideo":
        colors[0] = "#000035"
        colors[1] = "#000"
        break
      case "unity":
        colors[0] = "#111"
        colors[1] = "#000"
        break
      case "blog":
        colors[0] = "#000"
        colors[1] = "#0e0d0d"
        break
      default:
        colors[0] = "#930009"
        colors[1] = "#980069"
        break
    }
    return colors
  }

  render() {
    const linksConfig = [
      {
        link: "/portfolio",
        text: "Portfolio",
      },
      {
        link: "/contact",
        text: "Contact",
      },
    ]

    const linkCSS = function(text, props) {
      let location = props.location.pathname.split("/").join("")
      let isActive =
        location === text.toLowerCase() ||
        location.indexOf(text.toLowerCase()) !== -1
      return css`
        color: #fff;
        font-size: 17px;
        transition: all 0.5s;
        box-sizing: border-box;
        border-bottom: 1px solid ${isActive ? "#fff" : "transparent"};
        padding: 0 !important;
        margin: 0.5rem 1rem;
        :hover {
          color: #fff;
          border-bottom: 1px solid #fff;
        }
        @media (max-width: 576px) {
          :hover {
            color: #fff;
            border-bottom: 1px solid transparent;
          }
        }
      `
    }

    let headerHeight = this.props.headerType === "home" ? "600px;" : "150px;"
    let titleMargin = this.props.headerType === "home" ? "280px;" : "150px;"

    return (
      <header
        css={css`
          width: 100%;
        `}
      >
        <Nav
          fill={false}
          horizontal={"end"}
          className="navbar navbar-expand-sm navbar-dark"
          css={css`
            @media (max-width: 576px) {
              background: -moz-linear-gradient(
                -45deg,
                ${this.getHeaderColors()[0]} 0%,
                ${this.getHeaderColors()[1]} 100%
              );
              background: -webkit-linear-gradient(
                -45deg,
                ${this.getHeaderColors()[0]} 0%,
                ${this.getHeaderColors()[1]} 100%
              );
              background: linear-gradient(
                135deg,
                ${this.getHeaderColors()[0]} 0%,
                ${this.getHeaderColors()[1]} 100%
              );
            }
            @media (min-width: 576px) {
              position: absolute !important;
              top: 10px;
              width: 100%;
              z-index: 100;
              background-color: transparent;
            }
            @media (max-width: 576px) {
              ${this.props.headerType !== "home" ? `margin-bottom: 20px` : null}
            }
          `}
        >
          <a
            href="/"
            className="navbar-brand"
            css={css`
              @media (max-width: 576px) {
                margin-right: auto !important;
              }
            `}
          >
            <Logo
              css={css`
                width: 50px;
                height: 50px;
                fill: #fff;
                z-index: 30;
                margin-left: 10px;
              `}
            />{" "}
            <span
              css={css`
                font-family: Nunito;
                padding-left: 10px;
                display: inline-block;
              `}
            >
              Original Wonders
            </span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              {linksConfig.map(item => {
                return (
                  <NavItem key={item.text}>
                    <NavLink
                      key={item.text}
                      href={item.link}
                      css={linkCSS(item.text, this.props)}
                    >
                      {item.text}
                    </NavLink>
                  </NavItem>
                )
              })}
            </ul>
          </div>
        </Nav>
        <div
          css={css`
            @media (max-width: 576px) {
              ${this.props.headerType !== "home" ? `display: none;` : null}
            }
          `}
        >
          {this.props.headerType === "home" &&
            (this.state.homeimage === "home" ? (
              <HeaderBackgroundContainer1 />
            ) : (
              <HeaderBackgroundContainer2 />
            ))}
          <Container
            fluid={true}
            css={css`
              position: absolute;
              z-index: 30;
              color: #fff;
              @media (max-width: 576px) {
                ${this.props.headerType !== "home" ? `display:none;` : null}
              }
            `}
          >
            {this.props.headerType === "home" && (
              <Row>
                <Col xs="12" className="text-center">
                  <h2
                    css={css`
                      margin-top: ${titleMargin};
                      font-size: 28px;
                      font-weight: 400;
                      font-family: Nunito;
                    `}
                  >
                    {this.props.headerText}
                  </h2>
                </Col>
              </Row>
            )}
          </Container>
          <Container
            fluid={true}
            css={css`
							padding: 0;
							width: 100%;
							background: #930009; /* Old browsers */
							background: -moz-linear-gradient(-45deg, ${this.getHeaderColors()[0]} 0%, ${
              this.getHeaderColors()[1]
            } 100%);
							background: -webkit-linear-gradient(-45deg, ${this.getHeaderColors()[0]} 0%, ${
              this.getHeaderColors()[1]
            } 100%);
							background: linear-gradient(135deg, ${this.getHeaderColors()[0]} 0%, ${
              this.getHeaderColors()[1]
            } 100%);
							position: relative;
							height: ${headerHeight}
							opacity: ${this.props.headerType === "home" ? "0.85;" : "0.80;"}
							margin-bottom: 60px;
							z-index: 20;
							`}
          ></Container>
          <AngleCover rotation="-3deg" topMargin="-100px" />
        </div>
      </header>
    )
  }
}

export default props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
)
